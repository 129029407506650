import React from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import { useSolarClock } from '../../providers/solar-clock-provider';
import { Days } from '../../hooks/use-current-time';
import { formatDayTimeAndZone } from '../../utils/time';
import { SunTypes } from '../../domain/sun-type';

export default function DebugModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  const {
    coordinates,
    location,
    timezone,
    currentTime,
    displayDate,
    today,
    tomorrow,
    sunTimes,
    moonPhase,
    activeEvent,
  } = useSolarClock();

  return (
    <Modal
      show={isModalOpen(ModalTypes.Debug)}
      onClose={closeModal}
      darkTheme={false}
      sunType={SunTypes.Red}
    >
      <div className="debug-container">
        <div>
          <span>Location:</span>
          <span>{JSON.stringify(location, undefined, 2)}</span>
        </div>
        <div>
          <span>Timezone:</span>
          <span>{timezone}</span>
        </div>
        <div>
          <span>Coordinates:</span>
          <span>{JSON.stringify(coordinates, undefined, 2)}</span>
        </div>
        <div>
          <span>Current Time:</span>
          <span>{formatDayTimeAndZone(currentTime)}</span>
        </div>
        <div>
          <span>Display Date:</span>
          <span>{formatDayTimeAndZone(displayDate)}</span>
        </div>
        <div>
          <span>Today:</span>
          <span>{formatDayTimeAndZone(today)}</span>
        </div>
        <div>
          <span>Tomorrow:</span>
          <span>{formatDayTimeAndZone(tomorrow)}</span>
        </div>
        <div>
          <span>Active Event:</span>
          <span>{activeEvent}</span>
        </div>
        <div>
          <span>Sun Times:</span>
          <span>{JSON.stringify(sunTimes, undefined, 2)}</span>
        </div>
        <div>
          <span>MoonPhase:</span>
          <span>{JSON.stringify(moonPhase, undefined, 2)}</span>
        </div>
      </div>
    </Modal>
  );
}
