import React, { createContext, useState, useContext } from 'react';

import infoPanelDataMap from '../data/info-panel-content';
import '../css/Tooltip.css';
import useBrowserInfo from '../hooks/use-browser-info';
import SlidingPanel from './SlidingPanel';

const InfoPanelContext = createContext();

export const InfoPanelState = {
  Closed: 'closed',
  Preview: 'preview',
  Full: 'full',
};

const DefaultContent = {
  content: 'No content available',
  position: 'nadir',
  state: InfoPanelState.Closed,
};

export const InfoPanelProvider = ({ children }) => {
  const { isMobileLayout } = useBrowserInfo();
  const [infoPanel, setInfoPanel] = useState(DefaultContent);

  const showInfoPanelPreview = (name) => {
    const state = InfoPanelState.Preview;
    const infoPanelState = {
      name,
      content: '',
      position: undefined,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
  };

  const showInfoPanel = (name) => {
    const { content, position } = infoPanelDataMap[name] || DefaultContent;
    const infoPanelContent = (
      <div>
        <h3>{name}</h3>
        {content}
      </div>
    );
    const state = InfoPanelState.Full;
    const infoPanelState = {
      name,
      content: infoPanelContent,
      position,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
  };

  const hideInfoPanel = () => {
    const state = InfoPanelState.Closed;
    const infoPanelState = {
      ...infoPanel,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
  };

  return (
    <InfoPanelContext.Provider
      value={{ infoPanel, showInfoPanelPreview, showInfoPanel, hideInfoPanel }}
    >
      {children}
      {infoPanel.state !== 'closed' && !isMobileLayout && (
        <Tooltip content={infoPanel.content} position={infoPanel.position} />
      )}
      {isMobileLayout && (
        <SlidingPanel
          name={infoPanel.name}
          content={infoPanel.content}
          state={infoPanel.state}
        />
      )}
    </InfoPanelContext.Provider>
  );
};

const Tooltip = ({ content, position }) => {
  return <div className={`tooltip ${position}`}>{content}</div>;
};

export const useInfoPanel = () => useContext(InfoPanelContext);
