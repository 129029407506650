import { adjustDay } from './time';
import { sleep } from './async';
import { DateTime } from 'luxon';

export function waitForRef(ref, interval = 100) {
  return new Promise((resolve, reject) => {
    // Timeout to prevent infinite loop
    const timeout = setTimeout(() => {
      clearInterval(checkInterval);
      if (!ref.current) {
        console.warn('Timeout waiting for ref to be present');
      }
      resolve(undefined);
    }, 5000); // Timeout after 5 seconds

    const checkInterval = setInterval(() => {
      if (ref.current) {
        clearInterval(checkInterval);
        clearTimeout(timeout);
        resolve(ref.current);
      }
    }, interval);
  });
}

export function createSunPathAnimation(
  startTime,
  stopTime,
  sunrise,
  sunset,
  onFrame,
  duration,
  delay = 0,
  hold = 0,
) {
  // Return a promise that resolves when the interval is cleared
  return {
    start: () => {
      const sameDayStopTime = adjustDay(startTime, stopTime);
      const startMillis = startTime.toMillis();
      const stopMillis = sameDayStopTime.toMillis();

      const frameDuration = 1000 / 60; // Duration per frame in milliseconds (assuming 60 fps)
      const totalFrames = duration * 60; // Total number of frames for the duration
      const timeDifference = stopMillis - startMillis; // Difference between start and stop time in milliseconds

      let currentFrame = 0;
      let started = false;

      return new Promise(async (resolve) => {
        if (delay > 0) {
          await sleep(delay);
        }

        const animationTimer = setInterval(() => {
          if (!started) {
            started = true;
          }
          if (currentFrame < totalFrames) {
            // Calculate current time, accounting for direction of animation
            const progress = currentFrame / totalFrames;
            const currentTime = startMillis + timeDifference * progress;
            const currentDateTime = DateTime.fromMillis(currentTime);

            const isDarkTheme = currentTime < sunrise || currentTime > sunset;
            onFrame(currentDateTime, isDarkTheme);
            currentFrame++;
          } else {
            clearInterval(animationTimer);

            // After the animation is complete, hold the final position for the specified duration
            if (hold) {
              setTimeout(() => {
                resolve(); // Resolve the promise after holding
              }, hold);
            } else {
              resolve(); // Resolve the promise immediately if no hold
            }
          }
        }, frameDuration);
      });
    },
  };
}
