import React, { useEffect, useState } from 'react';
import { useSolarClock } from '../providers/solar-clock-provider';
import { formatNamedDay, formatDayTimeAndZone, formatDay } from '../utils/time';
import DateSelector from './date_selector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LocationSearchModal from './modals/location-search-modal';
import SignUpModal from './modals/sign-up';
import useBrowserInfo from '../hooks/use-browser-info';
import { CircadianEventType } from './circadian_event';
import { Features } from '../utils/features';
import { ModalTypes, useModal } from '../providers/modal-provider';
import DebugModal from './modals/debug-modal';
import { useAuth } from '../providers/auth-provider';
import { useInfoPanel } from './info-panel';

function AppHeader({ darkTheme, showTime = false, sunType }) {
  const { debug: isDebugVisible } = useParams();
  const [isControlsVisible, setIsControlsVisible] = useState(false);
  const { displayDate, activateEvent, isDateToday } = useSolarClock();
  const navigate = useNavigate();
  const { isMobileLayout } = useBrowserInfo();
  const { openModal } = useModal();
  const { isAuthenticated } = useAuth();
  const { hideInfoPanel } = useInfoPanel();

  const headerClass = darkTheme ? 'header dark-mode-text-color' : 'header';
  const timeClass = darkTheme ? 'headerTime dark-mode-text-color' : 'headerTime';

  useEffect(() => {
    if (!isAuthenticated) {
      openModal(ModalTypes.InviteOnly);
    }
  }, [isAuthenticated]);

  const openDateSelector = () => {
    if (Features.allowDateSelectIsActive()) {
      setIsControlsVisible(true);
    }
  };
  const closeDateSelector = () => setIsControlsVisible(false);

  const handleDateChange = (newDate) => {
    activateEvent(CircadianEventType.Sunrise, newDate);
  };

  const navigateHome = () => {
    hideInfoPanel();
    navigate('/');
  };

  return (
    <div className={headerClass}>
      <div>
        <h1 onClick={navigateHome}>The Healing Sun</h1>
      </div>
      {showTime && isDebugVisible && (
        <div className={timeClass} onClick={() => openModal(ModalTypes.Debug)}>
          Open Debug Modal
        </div>
      )}
      {showTime && !isControlsVisible && (
        <div className={timeClass} onClick={openDateSelector}>
          {isMobileLayout && isDateToday()
            ? formatDayTimeAndZone(displayDate)
            : formatNamedDay(displayDate)}
        </div>
      )}
      {showTime && isControlsVisible && (
        <div className={timeClass}>
          <DateSelector
            onChange={handleDateChange}
            displayDate={displayDate}
            onClose={closeDateSelector}
          />
        </div>
      )}
      <LocationSearchModal darkTheme={darkTheme} sunType={sunType} />
      <SignUpModal darkTheme={darkTheme} sunType={sunType} />
      <DebugModal darkTheme={darkTheme} sunType={sunType} />
    </div>
  );
}

export default AppHeader;
