export const loadDataFromCache = async (tag) => {
  // Check if data is already in localStorage
  const cachedData = localStorage.getItem(tag);
  if (cachedData) {
    return JSON.parse(cachedData);
  }
};

export const saveDataToCache = async (tag, data) => {
  // Store the fetched data in localStorage
  localStorage.setItem(tag, JSON.stringify(data));
};

const LocationDataTag = 'LOCATION_DATA';

export async function loadLocationDataFromCache() {
  return await loadDataFromCache(LocationDataTag);
}

export const saveLocationDataToCache = async (data) => {
  await saveDataToCache(LocationDataTag, data);
};
