import React from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import AddSubscriber from '../add-subscriber';
import { useAuth } from '../../providers/auth-provider';

export default function SignUpModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  const modalResultsClass = darkTheme ? 'modal-results modal-dark' : 'modal-results';
  const { isAuthenticated } = useAuth();

  const isInviteCodeModal = isModalOpen(ModalTypes.InviteOnly);
  return (
    <Modal
      show={isModalOpen(ModalTypes.SignUp) || isInviteCodeModal}
      onClose={closeModal}
      darkTheme={darkTheme}
      sunType={sunType}
      allowClose={!isInviteCodeModal}
    >
      <AddSubscriber isCodeRequired={isInviteCodeModal && !isAuthenticated} />
    </Modal>
  );
}
