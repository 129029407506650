import { useEffect, useState } from 'react';
import {
  getSolarGradientBySunType,
  getSunTypeGradient,
  SolarGradients,
} from '../domain/sun-type';
import { SunTypes } from '../components/sun_path';
import { sleep } from '../utils/async';

/* Animation of Gradients */

function interpolateColor(color1, color2, ratio) {
  const r = Math.round(color1.r + (color2.r - color1.r) * ratio);
  const g = Math.round(color1.g + (color2.g - color1.g) * ratio);
  const b = Math.round(color1.b + (color2.b - color1.b) * ratio);
  return { r, g, b };
}

function createColorAnimation(gradient1, gradient2, duration) {
  const colors1 = getSolarGradientBySunType(gradient1);
  const colors2 = getSolarGradientBySunType(gradient2);
  const numFrames = duration * 60; // 60 frames per second

  return new Promise((resolve, reject) => {
    let currentFrame = 0;
    const interval = setInterval(() => {
      if (currentFrame >= numFrames) {
        clearInterval(interval);
        resolve(); // Resolve the promise when animation completes
        return;
      }

      const ratio = currentFrame / numFrames;
      const intermediateColors = colors1.map((color1, index) => {
        const color2 = colors2[index] || colors2[colors2.length - 1]; // If gradient2 has fewer colors, use the last color
        return interpolateColor(color1.color, color2.color, ratio);
      });

      const gradientString = intermediateColors
        .map((color) => `rgb(${color.r}, ${color.g}, ${color.b})`)
        .join(',');
      document.body.style.background = `linear-gradient(to top, ${gradientString})`;
      currentFrame++;
    }, 1000 / 60); // 60 frames per second
  });
}

function createPercentageAnimation(gradient1, gradient2, duration) {
  const colors1 = getSolarGradientBySunType(gradient1);
  const colors2 = getSolarGradientBySunType(gradient2);
  const numFrames = duration * 60; // 60 frames per second

  return new Promise((resolve, reject) => {
    let currentFrame = 0;
    const interval = setInterval(() => {
      if (currentFrame >= numFrames) {
        clearInterval(interval);
        resolve(); // Resolve the promise when animation completes
        return;
      }

      const ratio = currentFrame / numFrames;
      const intermediateColors = colors1.map((color1, index) => {
        const color2 = colors2[index] || colors2[colors2.length - 1]; // If gradient2 has fewer colors, use the last color
        const pct = color1.pct + (color2.pct - color1.pct) * ratio; // Interpolate percentage
        return { ...color1, pct };
      });

      const gradientString = intermediateColors
        .map(
          (color) =>
            `rgb(${color.color.r}, ${color.color.g}, ${color.color.b}) ${color.pct}%`,
        )
        .join(', ');
      document.body.style.background = `linear-gradient(to top, ${gradientString})`;
      currentFrame++;
    }, 1000 / 60); // 60 frames per second
  });
}

function createCombinedAnimation(gradient1, gradient2, duration) {
  const colors1 = getSolarGradientBySunType(gradient1);
  const colors2 = getSolarGradientBySunType(gradient2);
  const numFrames = duration * 60; // 60 frames per second

  return new Promise((resolve, reject) => {
    let currentFrame = 0;
    const interval = setInterval(() => {
      if (currentFrame >= numFrames) {
        clearInterval(interval);
        resolve(); // Resolve the promise when animation completes
        return;
      }

      const ratio = currentFrame / numFrames;
      const intermediateColors = colors1.map((color1, index) => {
        const color2 = colors2[index] || colors2[colors2.length - 1]; // If gradient2 has fewer colors, use the last color
        const intermediateColor = interpolateColor(color1.color, color2.color, ratio);
        const pct = color1.pct + (color2.pct - color1.pct) * ratio; // Interpolate percentage
        return { color: intermediateColor, pct };
      });

      const gradientString = intermediateColors
        .map(
          (color) =>
            `rgb(${color.color.r}, ${color.color.g}, ${color.color.b}) ${color.pct}%`,
        )
        .join(', ');
      document.body.style.background = `linear-gradient(to top, ${gradientString})`;
      currentFrame++;
    }, 1000 / 60); // 60 frames per second
  });
}

export const SunTypeProgression = [
  SunTypes.Night,
  SunTypes.BlueHour,
  SunTypes.Twilight,
  SunTypes.Red,
  SunTypes.UVA,
  SunTypes.UVB,
  SunTypes.SolarNoon,
];

async function runBackgroundAnimation(
  startType = SunTypes.Night,
  endType = SunTypes.UVB,
  direction = 'forward',
  delay = 1250,
  duration = 3,
) {
  // Find the first index of startType
  const startIndex = SunTypeProgression.indexOf(startType);

  // Find the last index of endType
  const endIndex = SunTypeProgression.indexOf(endType);

  // Validate the indices
  if (startIndex === -1 || endIndex === -1) {
    throw new Error('Invalid startType or endType');
  }

  const clipDuration = Math.abs(duration / (endIndex - startIndex));
  await sleep(delay);
  // Loop through the progression from startIndex to endIndex
  if (direction === 'forward') {
    for (let i = startIndex; i < endIndex; i++) {
      await createCombinedAnimation(
        SunTypeProgression[i],
        SunTypeProgression[i + 1],
        clipDuration,
      );
    }
  } else if (direction === 'reverse') {
    for (let i = startIndex; i > endIndex; i--) {
      await createCombinedAnimation(
        SunTypeProgression[i],
        SunTypeProgression[i - 1],
        clipDuration,
      );
    }
  } else {
    throw new Error('Invalid direction', direction);
  }
}

function setBackgroundGradient(sunType) {
  // Apply the background style to the body element
  document.body.style.background = getSunTypeGradient(sunType);
  document.body.style.height = '100vh'; // Ensure the whole viewport is covered
}

export const useBackgroundAnimation = (
  onAnimationEnd = () => {},
  stages = [
    {
      start: SunTypes.Night,
      stop: SunTypes.UVB,
      direction: 'forward',
      delay: 1250,
    },
  ],
) => {
  const [isAnimationRunning, setAnimationRunning] = useState(false);

  const startAnimation = async () => {
    if (isAnimationRunning) {
      return;
    }

    setAnimationRunning(true);
    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      await runBackgroundAnimation(
        stage.start,
        stage.stop,
        stage.direction,
        stage.delay,
        stage.duration,
      );
    }
    setAnimationRunning(false);
    if (onAnimationEnd) {
      onAnimationEnd();
    }
  };

  return {
    startBackgroundAnimation: startAnimation,
    isBackgroundAnimationRunning: isAnimationRunning,
  };
};

export function displayBackgroundGradient(sunType) {
  setBackgroundGradient(sunType);
}

export const useBackground = (sunType) => {
  useEffect(() => {
    return displayBackgroundGradient(sunType);
  }, [sunType]);
};
