const isLoggingEnabled = process.env.ENABLE_LOGGING === 'true';

const logger = {
  log: (...args) => {
    if (isLoggingEnabled) {
      logger.log(...args);
    }
  },
  info: (...args) => {
    if (isLoggingEnabled) {
      console.info(...args);
    }
  },
  warn: (...args) => {
    if (isLoggingEnabled) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    if (isLoggingEnabled) {
      console.error(...args);
    }
  },
};

export default logger;
