import { useMemo, useEffect, useState } from 'react';

export default function useBrowserInfo() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobileLayout = useMemo(() => windowWidth <= 768, [windowWidth]);

  function isTouchScreen() {
    return window.matchMedia('(max-width: 767px) and (pointer: coarse)').matches;
  }

  return { windowWidth, windowHeight, isMobileLayout, isTouchScreen };
}
