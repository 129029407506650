import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const checkCookie = (cookieName) => {
  return Cookies.get(cookieName);
};

const setCookie = (cookieName, cookieValue, options) => {
  Cookies.set(cookieName, cookieValue, options);
};

export const useAuth = () => {
  // Custom hook to access context values
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const AuthCookieName = 'SunlightIsLifeAuthCookie';
const AuthContext = createContext();

function simpleHash(inviteCode) {
  let hash = 5381;
  for (let i = 0; i < inviteCode.length; i++) {
    hash = (hash * 33) ^ inviteCode.charCodeAt(i);
  }
  return hash >>> 0;
}

const InviteCodesStore = [2089166014, 228850785, 216707547];

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    // Check if the cookie exists
    if (!checkCookie(AuthCookieName)) {
      setIsAuthenticated(false);
    }
  }, []);

  function isValidCode(inviteCode) {
    if (inviteCode == null) {
      return false;
    }
    const hashedInviteCode = simpleHash(inviteCode.toUpperCase());

    return InviteCodesStore.includes(hashedInviteCode);
  }

  function authenticate() {
    setCookie(AuthCookieName, true, { expires: 365 }); // Expires in 365 days
    setIsAuthenticated(true);
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, authenticate, isValidCode }}>
      {children}
    </AuthContext.Provider>
  );
}
