import React, { useState } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import { useSolarClock } from '../../providers/solar-clock-provider';
import Modal from './modal';
import { fetchLocationData, searchLocation } from '../../utils/geo';
import ThreeDotsBounce from '../../icons/three-dots-bounce';

function LocationForm({ location, onSuccess, onFailure, onSubmit }) {
  const [inputLocation, setInputLocation] = useState(location);
  const [loading, setLoading] = useState(undefined);

  const onChangeLocation = (event) => {
    setInputLocation(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit();
    setLoading('search');
    const locationsList = await searchLocation(inputLocation);
    setLoading(undefined);
    onSuccess(locationsList);
  };

  const handleDetectLocation = async (event) => {
    event.preventDefault();
    onSubmit();
    setLoading('detect');
    try {
      const location = await fetchLocationData(false);
      onSuccess([location.location]);
    } catch (e) {
      onFailure(
        'There was an error detecting your location. Please try again, or search manually for your location.',
      );
    }
    setLoading(undefined);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="location-form">
        <input
          type="text"
          value={inputLocation}
          onChange={onChangeLocation}
          className="form-input-medium"
        />
        <button
          type="submit"
          className="gradient-button button-medium"
          disabled={loading !== undefined}
        >
          {loading === 'search' ? <ThreeDotsBounce /> : 'Search'}
        </button>
        <button
          onClick={handleDetectLocation}
          className="gradient-button button-medium"
          disabled={loading !== undefined}
        >
          {loading === 'detect' ? <ThreeDotsBounce /> : 'Detect Location'}
        </button>
      </div>
    </form>
  );
}

function LocationItem({ location, onSelect }) {
  console.log('Displaying location', location);
  const { id, displayName } = location;
  return (
    <div key={id} className="modal-result-item" onClick={() => onSelect(location)}>
      {displayName}
    </div>
  );
}

export default function LocationSearchModal({ darkTheme, sunType }) {
  const [locations, setLocations] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const { isModalOpen, closeModal } = useModal();
  const { location, selectLocation } = useSolarClock();

  const modalResultsClass = darkTheme ? 'modal-results modal-dark' : 'modal-results';

  function clearState() {
    setLocations(undefined);
    setErrorMessage(undefined);
  }

  function onSelectLocation(location) {
    selectLocation(location);
    closeModal();
    clearState();
  }

  const onSuccess = (locations) => setLocations(locations);
  const onFailure = (msg) => setErrorMessage(msg);

  const handleCloseModal = () => {
    clearState();
    closeModal();
  };

  return (
    <Modal
      show={isModalOpen(ModalTypes.LocationSearch)}
      onClose={handleCloseModal}
      darkTheme={darkTheme}
      sunType={sunType}
    >
      <h2>Find a Location</h2>
      <LocationForm
        location={location}
        onSuccess={onSuccess}
        onFailure={onFailure}
        onSubmit={clearState}
      />
      <hr />
      {errorMessage && <div>{errorMessage}</div>}
      {locations && (
        <div className={modalResultsClass}>
          {locations.map((location) => (
            <LocationItem
              key={location.id}
              location={location}
              onSelect={onSelectLocation}
            />
          ))}
        </div>
      )}
    </Modal>
  );
}
