import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/LearnMore.css';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';

const CircadianBiology = (
  <>
    {' '}
    <h2>Introduction to Circadian Biology</h2>
    <p>
      Circadian biology is the study of how organisms, including humans, adapt their
      biological processes to the 24-hour day-night cycle. Two critical components driving
      these rhythms are photoreceptors and temperature receptors. The importance of light
      in regulating circadian rhythms was recognized as early as the 18th century when
      Jean-Jacques d'Ortous de Mairan observed plant movements in constant darkness,
      hinting at an internal clock. In humans, photoreceptors in the eye play a crucial
      role in synchronizing our internal clock with the external environment. While rods
      and cones are responsible for vision, it is the intrinsically photosensitive retinal
      ganglion cells (ipRGCs) that are pivotal for circadian regulation. These cells
      contain melanopsin, a photopigment especially sensitive to blue light (~480 nm),
      allowing ipRGCs to respond to ambient light levels and convey this information
      directly to the brain's master clock, the suprachiasmatic nucleus (SCN), via the
      retinohypothalamic tract (RHT).
    </p>
    <p>
      The discovery of these specialized cells and their functions in the 1990s
      significantly advanced our understanding of how light influences biological rhythms.
      Exposure to light, particularly in the morning, helps reset the circadian clock,
      aligning it with the 24-hour day. Conversely, evening light can delay the clock,
      affecting sleep patterns and overall well-being. Light also influences the
      production of melatonin, a hormone that signals nighttime to our body, highlighting
      the importance of proper light exposure for maintaining healthy sleep-wake cycles.
    </p>
    <p>
      Temperature receptors add another layer of regulation by providing cues about both
      external and internal temperatures. Peripheral thermoreceptors found in the skin
      detect environmental temperature changes and send signals to the brain, while
      central thermoreceptors located in the hypothalamus monitor core body temperature.
      This core temperature follows a circadian rhythm, typically peaking in the late
      afternoon and dipping in the early morning. Daily temperature fluctuations help
      synchronize various physiological processes, reinforcing the signals from light to
      keep our internal clock in tune with the environment.
    </p>
    <p>
      The integration of light and temperature signals by the SCN ensures that our
      internal clock remains synchronized with the external world. This synchronization
      optimizes physiological and behavioral processes, such as sleep-wake cycles,
      feeding, and metabolism. Light is the primary cue, directly influencing the SCN
      through ipRGCs, promoting wakefulness during the day, while darkness facilitates
      sleep at night. As a secondary cue, temperature changes provide additional
      information to fine-tune our circadian rhythms. Cooler night temperatures help
      promote sleep onset, while warmer daytime temperatures support alertness and
      activity.
    </p>
    <p>
      Photoreceptors and temperature receptors are vital for regulating circadian rhythms.
      By integrating signals from light and temperature, our internal clock can
      effectively synchronize with the external environment, ensuring optimal health and
      functioning. Understanding these mechanisms can help improve sleep, productivity,
      and overall well-being. The study of circadian biology continues to uncover the
      intricate details of how these systems work, offering new insights and applications
      for enhancing human health.
    </p>
  </>
);

const QuantumBiology = (
  <>
    <h2>Quantum Biological Foundations</h2>
    <p>
      While the molecular biology of circadian rhythms has been extensively studied,
      recent research has also explored the quantum biological aspects of these processes.
      Quantum biology, an emerging field, investigates the role of quantum phenomena in
      biological systems. A key aspect of quantum biology in circadian science is the
      photoelectric effect, discovered by Albert Einstein, which explains how different
      spectra of light from the sun at various times of the day interact with electrons in
      our bodies, ejecting those electrons and triggering physiological reactions. This
      principle is crucial in understanding how light influences biological systems,
      including the regulation of circadian rhythms.
    </p>

    <h3>Infrared Light and Cellular Energy Production</h3>
    <p>
      Infrared light, prevalent during sunrise and sunset, penetrates deeply into the
      tissues and can influence the TCA cycle and the electron transport chain within
      mitochondria. The TCA cycle occurs within our Mitochondria, "the powerhouses of the
      cell", which are responsible for producing energy. The electron transport chain is a
      series of complexes within the mitochondria that transfer electrons, creating a flow
      of energy used to produce ATP, the cell’s main energy currency. Infrared light also
      interacts with water within our cells, promoting the formation of a structured form
      of water that forms near internal surfaces and exhibits charge separation, creating
      a more organized and efficient environment for cellular processes. This charge
      separation functions like a battery, storing energy that can be used to power
      various cellular functions. This light energy can enhance ATP production by
      increasing the efficiency of these processes—through a quantum effect called
      electron tunneling—supporting cellular energy needs and overall metabolic health.
      The interaction of infrared light with cytochrome c oxidase, a key enzyme in the
      electron transport chain, can improve mitochondrial function and promote cellular
      repair and regeneration.
    </p>

    <h3>Blue Light and the Body's Master Clock</h3>
    <p>
      Blue light, abundant in daylight, has a significant impact on the suprachiasmatic
      nucleus (SCN) in the hypothalamus, which is the master clock of the body.
      Specialized photoreceptor cells in the retina, containing the photopigment
      melanopsin, are highly sensitive to blue light. The quantum effects of light are
      non-linear, meaning it takes very few photons of blue light—as little as five-to
      trigger an effect, so even minimal exposure to blue light can significantly
      influence the SCN. When blue light enters the eyes, it stimulates these cells,
      signaling the SCN to synchronize the body's circadian rhythms with the external
      light-dark cycle. This regulation affects sleep-wake cycles, hormone release, and
      other physiological processes.
    </p>

    <h3>UVA Light and Neurotransmitter Production</h3>
    <p>
      Ultraviolet A (UVA) light, part of the sunlight spectrum, can penetrate the skin and
      influence the production of neurotransmitters, which are chemicals that transmit
      signals in the brain. Exposure to UVA light can impact the synthesis of nitric
      oxide, a molecule that plays various roles in the body, including the modulation of
      serotonin and melatonin production in the pineal gland. Serotonin is often referred
      to as the "feel-good" neurotransmitter, while melatonin is known as the "sleep
      hormone." These neurotransmitters are crucial for regulating mood, sleep patterns,
      and overall circadian rhythms. By modulating these neurotransmitters, UVA light
      exposure can help maintain healthy sleep cycles and improve mood. Additionally, UVA
      light assists in balancing the hormone production stimulated by blue light through a
      process called sulfation. This helps in the proper functioning of various
      physiological processes. UVA light also brings melanin to the surface of the skin
      through melanocytes, providing protection against DNA damage and contributing to
      skin pigmentation.
    </p>

    <h3>UVB Light and Peptide Activation</h3>
    <p>
      Ultraviolet B (UVB) light has profound effects on the skin and endocrine system.
      When UVB light reaches the skin, it triggers the cleavage of a precursor protein
      called pro-opiomelanocortin (POMC) into several biologically active peptides. These
      peptides include beta-endorphin, which has pain-relieving and mood-enhancing
      effects, and alpha-melanocyte-stimulating hormone (α-MSH), which stimulates melanin
      production and contributes to skin pigmentation. Additionally, UVB light is crucial
      for the synthesis of vitamin D in the skin, which is essential for bone health,
      immune function, and overall well-being. By promoting the production of these
      peptides and vitamin D, UVB light helps support physical and mental health.
    </p>
  </>
);

const Conclusion = (
  <>
    <h2>Conclusion</h2>
    <p>
      Circadian science, rooted in centuries of observation and research, has unveiled the
      biophysical frameworks governing biological rhythms. The exploration of quantum
      biological mechanisms, such as the photoelectric effect and quantum tunneling,
      highlights the profound connections between light, electrons, and physiological
      processes. Emerging understandings in quantum biology reveal further complexities in
      biological systems, including the influence of magnetism on animal navigation, the
      role of quantum coherence in enzyme catalysis as explored by researchers like Jim
      Al-Khalili, and the properties of exclusion zone (EZ) water in cellular processes.
      These insights suggest that quantum effects are fundamental to life, influencing
      everything from metabolism to the way organisms interact with their environment.
    </p>

    <p>
      As we continue to uncover these intersections, circadian science promises to enhance
      our understanding of life’s rhythms and the fundamental principles that govern them.
      These quantum biological effects open new frontiers in biology, offering the
      potential for breakthroughs in health, technology, and our comprehension of, and our
      ability to connect with, the natural world.
    </p>
  </>
);
const content = [CircadianBiology, QuantumBiology, Conclusion];

const CircadianScience = () => {
  const contentRef = useRef(null);
  useBackground(SunTypes.Twilight);

  const { pageIndex } = useParams();
  const navigate = useNavigate();
  const pageIndexNumber = parseInt(pageIndex, 10);

  useEffect(() => {
    if (
      isNaN(pageIndexNumber) ||
      pageIndexNumber < 0 ||
      pageIndexNumber >= content.length
    ) {
      navigate('/learn-more/0');
    }
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [pageIndexNumber, navigate]);

  return (
    <div className="App">
      <AppHeader darkTheme={true} sunType={SunTypes.Twilight} />
      <div className="learn-more">
        <div ref={contentRef} className="content-main">
          {content[pageIndexNumber]}
        </div>
        <div className="navigation-buttons">
          <button
            onClick={() => navigate(`/learn-more/${pageIndexNumber - 1}`)}
            disabled={pageIndexNumber === 0}
          >
            Previous
          </button>
          <button
            onClick={() => navigate(`/learn-more/${pageIndexNumber + 1}`)}
            disabled={pageIndexNumber === content.length - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CircadianScience;
