import { useMemo } from 'react';
import SunCalc from 'suncalc';
import { DateTime } from 'luxon';
import { formatDayTimeAndZone } from '../utils/time';

SunCalc.addTime(10, 'uvaRise', 'uvaSet');
SunCalc.addTime(30, 'uvbRise', 'uvbSet');
SunCalc.addTime(0, 'zeroRise', 'zeroSet');
SunCalc.addTime(-1.125, '-oneRise', '-oneSet');

export function getSunTimes(coordinates, displayDate, timezone) {
  const { latitude, longitude } = coordinates;
  const {
    nauticalDawn: daybreak,
    dawn,
    sunrise,
    uvaRise,
    uvbRise,
    solarNoon,
    uvbSet,
    uvaSet,
    sunset,
    dusk,
    nauticalDusk: nightfall,
    nadir,
  } = SunCalc.getTimes(displayDate, latitude, longitude);

  const times = {
    sunrise,
    sunset,
    dawn,
    dusk,
    uvaRise,
    uvaSet,
    uvbRise,
    uvbSet,
    solarNoon,
    nightfall,
    daybreak,
    nadir,
  };

  const luxonTimes = {};
  for (const [key, value] of Object.entries(times)) {
    luxonTimes[key] = DateTime.fromJSDate(value, { zone: timezone });
  }
  return luxonTimes;
}

export function useSunTimes(coordinates, displayDate, timezone) {
  // Calculate sun times using SunCalc
  const sunTimes = useMemo(() => {
    return getSunTimes(coordinates, displayDate, timezone);
  }, [coordinates.latitude, coordinates.longitude, displayDate, timezone]);

  return sunTimes;
}
