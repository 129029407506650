import devFeatureFlags from '../config/features.dev.json';
import prodFeatureFlags from '../config/features.prod.json';

function loadFeatures() {
  const env = process.env.NODE_ENV;
  let featureFlags;

  if (env === 'development') {
    return devFeatureFlags;
  }
  return prodFeatureFlags;
}

const featureFlags = loadFeatures();

export const Features = {
  animateSolarClockIsActive: () => {
    return featureFlags.animateSolarClock;
  },
  allowLocationSearchIsActive: () => {
    return featureFlags.allowLocationSearch;
  },
  allowDateSelectIsActive: () => {
    return featureFlags.allowDateSelect;
  },
};
