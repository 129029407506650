import React from 'react';
import { SunTypes, MoonTypes } from '../components/sun_path';
import { UVASun, UVBSun, SunriseSun, SolarNoonSun } from './sun-svg-icon';
import MoonPhase from './moon-svg';
import { useInfoPanel } from '../components/info-panel';

const SunIcon = ({
  sunType = SunTypes.Red,
  progress,
  nextSunType,
  activeEvent,
  radius = 60,
  isMobile,
}) => {
  const { showInfoPanel } = useInfoPanel();

  const onClick =
    activeEvent && isMobile
      ? () => {
          showInfoPanel(activeEvent);
        }
      : undefined;

  if (sunType === SunTypes.UVA) {
    return (
      <UVASun
        progress={progress}
        nextSunType={nextSunType}
        onClick={onClick}
        radius={radius}
      />
    );
  }
  if (sunType === SunTypes.UVB) {
    return (
      <UVBSun
        progress={progress}
        nextSunType={nextSunType}
        onClick={onClick}
        radius={radius}
      />
    );
  }
  if (sunType === SunTypes.SolarNoon) {
    return (
      <SolarNoonSun
        progress={progress}
        nextSunType={nextSunType}
        onClick={onClick}
        radius={radius}
      />
    );
  }
  // Default to Red Sun
  return (
    <SunriseSun
      progress={progress}
      nextSunType={nextSunType}
      onClick={onClick}
      radius={radius}
    />
  );
};

export const isSunIcon = (sunType) => {
  return !MoonTypes.includes(sunType);
};

export const isMoonIcon = (sunType) => {
  return MoonTypes.includes(sunType);
};
export default SunIcon;
