import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { SolarClockProvider } from './providers/solar-clock-provider';
import { ModalProvider } from './providers/modal-provider';
import { InfoPanelProvider } from './components/info-panel';
import AuthProvider from './providers/auth-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <SolarClockProvider>
      <InfoPanelProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </InfoPanelProvider>
    </SolarClockProvider>
  </AuthProvider>,
);
