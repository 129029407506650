import React from 'react';
import { getFormattedTime } from '../utils/time';

export default function FloatingTime({ onClick, children }) {
  return (
    <div className="floating-time" onClick={onClick}>
      {children}
    </div>
  );
}

export const DigitalTime = ({ onMouseLeave, label, time, darkTheme, layout }) => {
  const className = darkTheme ? 'dark' : 'light';
  return (
    <span
      key={1}
      className={`clock-label clock-label-${layout} ${className}`}
      onMouseOver={onMouseLeave}
    >
      {label ? <div>{label}</div> : <div>Current Time</div>}
      {getFormattedTime(time)}
    </span>
  );
};
